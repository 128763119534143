import React from "react"
import Layout from "../components/layout"
import HelmetEx from "../components/helmet-ex"

export const bikeWarranty = () => {
  return (
    <div>
      <Layout>
        <section className="bike-warranty-section">
          <HelmetEx>
            <div metadata="title">Bike Warranty</div>
            <div metadata="keywords">
              indoor cycling, indoor cycle, interactive bike, streaming classes,
              virtual classes, stryde, stryde bike, virtual workouts
            </div>
            <div metadata="path">/faq</div>
            <div metadata="description">
              Access indoor cycling classes from country's top studios on an
              interactive bike at your home
            </div>
          </HelmetEx>
          <div className="container">
            <h4>Stryde Bike Limited Home Warranty</h4>
            <p>
              Katana Fitness, Inc.("Katana") extends the following Limited
              Warranty, which applies only to non-commercial, in-home, indoor
              use of the Stryde Bike. Any other use of the Bike shall void this
              Limited Warranty. During the applicable Limited Warranty periods
              described below, the covered components of the Stryde Bike will be
              free of defects or malfunctions during normal use. Certain
              exclusions apply, as further described in this Limited Warranty.
            </p>
            <p>
              The Limited Warranty extends only to the original purchaser of the
              Stryde bike or any person receiving a newly purchased Bike as a
              gift from the original purchaser. The Limited Warranty applies for
              the following components of the Stryde Bike only, for the time
              period indicated.
            </p>
            <h4>HD Touchsreen: 12 Months </h4>
            <p>
              We warrant the touchscreen against defects in workmanship and
              materials for a period of 12 months from the date of original
              delivery.
            </p>
            <h4>Frame: 5 Years</h4>
            <p>
              We warrant the frame, which excludes any moving parts attached to
              the structural frame, against defects in workmanship and materials
              for a period of five years from the date of original delivery.
            </p>
            <h4>Bike Parts: 12 Months </h4>
            <p>
              We warran the components and all original parts of the Bike
              against defects in workmanship and materials for a period of 12
              months from the date of original delivery. Bike pedals are not
              warranted against normal wear and tear and it is important that
              you maintain the pedals by inspecting the pedals regularly for any
              damage, loose parts or other signs of wear and tear that could
              cause breakdowns or part failures.
            </p>
            <h4>Labor: 12 Months </h4>
            <p>
              We will cover the labor cost for the repair or replacement made
              under this Limited Warranty for a period of 12 months from the
              date of original delivery.
            </p>
            <h3>WHAT YOU MUST DO TO OBTAIN SERVICE</h3>
            <p>
              • To be eligible for service under this Limited Warranty you must
              contact the our Support team. You will be asked to provide us with
              the serial number of your Bike and the dated receipt, or other
              proof of purchase indicating the date purchased, upon discovering
              any nonconformity or defect.
            </p>
            <h3>WHAT WE WILL DO TO CORRECT COVERED DEFECTS</h3>
            <p>
              {" "}
              • We will diagnose the problem over the phone. Afterwards our
              warranty service provider will dispatch a technician, ship to you
              any new or rebuilt replacement part or component or, at our
              option, replace the Bike. Any replacement or repaired component
              shall be warranted for the remainder of the original Warranty
              period or 30 days, whichever is longer, or for any additional
              period that is required by applicable law.
            </p>
            <h3>EXCLUSIONS</h3>
            <h4>This Warranty shall not apply to: </h4>
            <p>
              {" "}
              • Structural, functional or cosmetic damage, including rust,
              corrosion and unusual wear, caused by failure to follow the
              operation and maintenance procedures described in the owner’s
              manual or by use of the Bike with parts or accessories from third
              parties.
            </p>
            <p>
              {" "}
              • Software, even if sold with or embedded in the Bike, or Internet
              connectivity.
            </p>
            <p>
              {" "}
              • Service calls to correct installation of the equipment or
              instruct owners on how to use the equipment.
            </p>
            <p>
              {" "}
              • Damage or equipment failure due to accident, abuse, improper or
              abnormal use, neglect, corrosion, discoloration of paint or
              plastic, theft, vandalism, fire, flood, wind, lightning, freezing
              or other natural disasters or acts of God of any kind, electrical
              wiring, power reduction, power fluctuation or power failure from
              whatever cause, unusual atmospheric conditions, collision,
              introduction of foreign objects, or modifications that are
              unauthorized or not recommended
            </p>
            <p>
              {" "}
              • Any labor costs incurred beyond the applicable labor warranty
              period.
            </p>
          </div>
        </section>
      </Layout>
    </div>
  )
}
export default bikeWarranty
